import React from "react";
import {
  YMaps,
  Map,
  Placemark,
  GeolocationControl,
  ZoomControl,
} from "react-yandex-maps";
import Layout from "./layout";

export default function Contacts() {
  return (
    <Layout>
      <div className="xl:py-16 py-4 max-w-7xl mx-auto px-3 xl:px-0">
        <h2 className="text-2xl font-bold md:pb-4 underline font-header">
          Контакты
        </h2>
        <div className="grid md:grid-cols-4 gap-2 md:py-10">
          <div className="flex flex-col space-y-5">
            <div>
              <div className="text-gray-600 underline">Наш адрес:</div>
              <div>г. Краснодар, ул. Новороссийская, 3/12</div>
            </div>
            <div className="flex flex-col">
              <span className="text-gray-600 underline">Режим работы:</span>
              <div>ежедневно с 10:00 до 20:00</div>
              <div>пятница перерыв: 12:00–14:00</div>
            </div>
          </div>
          <div className="xl:col-span-3">
            <YMaps>
              <Map
                defaultState={{
                  center: [45.029776, 39.017345],
                  zoom: 16,
                }}
                className="w-full h-96"
              >
                <Placemark geometry={[45.029776, 39.017345]} />
                <GeolocationControl options={{ float: "left" }} />
                <ZoomControl options={{ float: "left" }} />
              </Map>
            </YMaps>
          </div>
        </div>
      </div>
    </Layout>
  );
}
